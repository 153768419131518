<template>
  <div class="app-manager">
    <el-button icon="el-icon-set-up" size="small" class="mb-4" @click="isOpen = !isOpen">
      Фильтр
    </el-button>
    <div v-if="isOpen" class="filter mb-4">
      <div class="deadline">
        <h5 class="mb-2">Фильтр по дедлайну</h5>
        <el-date-picker
          v-model="deadline"
          type="daterange"
          size="small"
          range-separator="-"
          start-placeholder="От"
          :picker-options="{ firstDayOfWeek: 1 }"
          end-placeholder="До"
          format="dd MMMM yyyy"
          value-format="yyyy-MM-dd"
          clearable
        />
      </div>
      <div class="created ml-5">
        <h5 class="mb-2">Фильтр по дате создания</h5>
        <el-date-picker
          v-model="created"
          type="daterange"
          size="small"
          range-separator="-"
          start-placeholder="От"
          :picker-options="{ firstDayOfWeek: 1 }"
          end-placeholder="До"
          format="dd MMMM yyyy"
          value-format="yyyy-MM-dd"
        />
      </div>
    </div>
    <div class="board">
      <el-row :gutter="20">
        <board-column
          v-for="(item, idx) in defaultTasks"
          :key="idx"
          :column="item"
          :reload-tasks="fetch"
        />
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import * as actionsTypes from '@/app/auth/shared/state/types/action_types';
import appGroupByDate from '../shared/services/app-group-by-date/app-group-by-date';
import BoardColumn from './board-column/board-column.vue';
import { FETCH_TASKS } from './shared/state/types/action_types';

const today = moment(Date.now()).format('YYYY-MM-DD');
const tommorow = moment(Date.now()).add(1, 'days').format('YYYY-MM-DD');
const tommorow1 = moment(Date.now()).add(2, 'days').format('YYYY-MM-DD');

export default {
  name: 'Manager',
  components: { BoardColumn },
  data() {
    return {
      deadline: [],
      created: [],
      query: {},
      isOpen: false,
      defaultData: [
        { label: moment(today).locale('ru').format('LL'), value: today },
        { label: moment(tommorow).locale('ru').format('LL'), value: tommorow },
        { label: moment(tommorow1).locale('ru').format('LL'), value: tommorow1 },
      ],
    };
  },
  watch: {
    deadline(value) {
      this.query = { ...this.query, deadline: value ? value.join() : undefined };
    },
    created(value) {
      this.query = { ...this.query, created: value ? value.join() : undefined };
    },
    query(value) {
      this.$router.replace({ query: value });
      const options = {
        date: today,
      };
      if (value.deadline) {
        options.deadline = value.deadline;
        delete options.date;
      }
      if (value.created) {
        options.created = value.created;
        delete options.date;
      }
      this.fetch(options);
    },
  },
  computed: {
    ...mapState({
      tasks: (state) =>
        state.managerState.managerData.data
          ? appGroupByDate(state.managerState.managerData.data.data)
          : [],
      me: (state) => state.authState.authState.me,
      isAvailable: (state) => ['manager'].includes(state.authState.authState.role),
    }),
    defaultTasks() {
      return this.tasks.length > 0 ? this.tasks : this.defaultData;
    },
  },
  async created() {
    if (!this.me) {
      await this.$store.dispatch(actionsTypes.ME);
    }
    if (this.isAvailable) {
      await this.fetch({ userId: this.me.id, date: today, ...this.$route.query });
      return;
    }
    await this.fetch({ date: today, ...this.$route.query });
    if (this.$route.query) {
      this.created = this.$route.query.created.split(',');
      this.deadline = this.$route.query.deadline.split(',');
    }
  },
  methods: {
    ...mapActions({
      fetch: FETCH_TASKS,
    }),
  },
};
</script>

<style lang="scss">
.board {
  height: 90vh;
  &__card {
    width: 100%;
    margin: 0 auto;
  }
  &__cards {
    max-height: 90vh;
    overflow-y: overlay;
  }
}
.switch {
  margin-bottom: 1rem;
}
.board {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}
.filter {
  display: flex;
  width: 100%;
}
</style>
