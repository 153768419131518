<template>
  <el-dialog :title="title" :visible.sync="visible" width="80%" @close="handleClose">
    <el-form v-loading="loading" label-position="top" :model="task">
      <el-row>
        <el-col :span="12">
          <el-form-item label="Дата задачи">
            <el-date-picker
              v-model="task.date"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="Выберите дату задачи"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Время задачи">
            <el-time-select
              v-model="task.time"
              :picker-options="{
                start: '00:00',
                step: '00:05',
                end: '23:55',
              }"
              placeholder="Выберите время задачи"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Описание задачи">
            <el-input
              v-model="task.description"
              type="textarea"
              :rows="4"
              placeholder="Напишите описание задачи"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Состояние задачи">
            <el-switch v-model="task.state" active-text="Выполнена" inactive-text="Не выполнена" />
          </el-form-item>
        </el-col>
        <el-col v-if="types" :span="12">
          <el-form-item v-if="task.type" label="Тип задачи">
            <el-select v-model="task.type.id" style="width: 90%" placeholder="Выберите тип задачи">
              <el-option
                v-for="item in types.data"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-else label="Тип задачи">
            <el-select v-model="task.type_id" style="width: 90%" placeholder="Выберите тип задачи">
              <el-option
                v-for="item in types.data"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="task.state" :span="24">
          <el-form-item label="Описание выполнения задачи">
            <el-input
              v-model="task.desc_state"
              type="textarea"
              :rows="4"
              placeholder="Напишите как выполнили эту задачу"
            />
          </el-form-item>
        </el-col>
        <el-col v-if="managers" :span="12">
          <el-form-item v-if="task.manager" label="Менеджер">
            <el-select
              v-model="task.manager.id"
              style="width: 90%"
              placeholder="Выберите менеджера"
            >
              <el-option
                v-for="item in managers.data"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-else label="Менеджер">
            <el-select v-model="task.user_id" style="width: 90%" placeholder="Выберите менеджера">
              <el-option
                v-for="item in managers.data"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="task.client" :span="24">
          <el-form-item label="Клиент">
            <el-tabs v-model="activeClientTab" border>
              <el-tab-pane label="Информация" name="info">
                <div class="client__info">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <span class="label">ФИО</span>
                      <span class="value">{{ task.client.name }}</span>
                    </el-col>
                    <el-col :span="12">
                      <span class="label">Баланс</span>
                      <span class="value">{{ task.client.balance }}</span>
                    </el-col>
                    <el-col :span="12">
                      <span class="label">Email</span>
                      <span class="value">{{ task.client.email }}</span>
                    </el-col>
                    <el-col :span="12">
                      <span class="label">Телефон</span>
                      <span class="value">{{ task.client.phone }}</span>
                    </el-col>
                    <el-col :span="12">
                      <span class="label">Мессенджер</span>
                      <span class="value">
                        {{ task.client.messenger }}
                        <strong>({{ task.client.messenger_login }})</strong>
                      </span>
                    </el-col>
                  </el-row>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Адреса" name="address">
                <general-table
                  :data="task.client.address"
                  :fields="fieldsAddress"
                  :loading="false"
                  :rules="[]"
                  :pagination="false"
                />
              </el-tab-pane>
              <el-tab-pane label="История" name="history" />
              <el-tab-pane label="Заказы" name="orders">
                <el-table :data="task.client.orders" style="width: 100%">
                  <el-table-column type="index" width="50" label="#" :index="indexMethod" />
                  <el-table-column prop="date" label="Дата" />
                  <el-table-column prop="data.directory.dish.title" label="Блюдо" />
                  <el-table-column prop="paid" label="Статус" />
                </el-table>
              </el-tab-pane>
            </el-tabs>
          </el-form-item>
        </el-col>
        <el-col v-else :span="12">
          <el-form-item label="Клиент">
            <autocomplete action="CLIENTS" :select="selectClent" :values.sync="client.name" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        v-if="task.id && !isManager"
        icon="el-icon-delete"
        type="danger"
        @click="handleDelete"
      >
        Удалить
      </el-button>
      <el-button type="primary" @click="sendDataTask">{{
        task.id ? 'Сохранить' : 'Добавить'
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import Autocomplete from '@/components/common/Autocomplete.vue';
import GeneralTable from '@/components/common/GeneralTable.vue';
import { FETCH_EMPLOYEES } from '@/app/settings/employes/shared/state/types/action_types';
import { FETCH_TASKS, FETCH_TYPES } from '../shared/state/types/action_types';

const today = moment(Date.now()).format('YYYY-MM-DD');

export default {
  name: 'BoardModal',
  components: { Autocomplete, GeneralTable },
  props: {
    title: {
      type: String,
      default: 'Добавить переписку',
    },
    show: {
      type: Boolean,
      default: false,
    },
    taskId: {
      type: Number,
      default: 0,
    },
    date: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      // types: [{ value: 1, label: 'Eum id.' }],
      activeClientTab: 'info',
      loading: false,
      client: {},
      fieldsAddress: [
        { label: '#', value: 'id' },
        { label: 'Область', value: 'region' },
        { label: 'Город', value: 'city' },
        { label: 'Улица', value: 'street' },
        { label: 'Дом', value: 'house' },
        { label: 'Под.', value: 'entrance' },
        { label: 'Кв.', value: 'apartment' },
      ],
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
    ...mapGetters({ task: 'TASK' }),
    ...mapState({
      me: (state) => state.authState.authState.me,
      managers: (state) => state.employesState.employesData.data,
      types: (state) => state.managerState.managerData.types,
      managerLoading: (state) => state.employesState.employesData.loading,
      isManager: (state) => ['manager'].includes(state.authState.authState.role),
    }),
  },
  async created() {
    if (this.date) {
      const time = moment(Date.now()).format('HH:mm');
      this.$store.commit('SET_TASK', {
        ...this.task,
        date: moment(this.date).format('YYYY-MM-DD'),
        time,
      });
    }
    if (this.taskId) {
      this.loading = true;
      await this.$store.dispatch('FETCH_TASK', this.taskId);
      this.loading = false;
    }
    await this.fetchManagers();
    await this.fetchTypes();
  },
  methods: {
    ...mapActions({
      fetchManagers: FETCH_EMPLOYEES,
      fetchTypes: FETCH_TYPES,
      fetch: FETCH_TASKS,
    }),
    indexMethod(index) {
      return index + 1;
    },
    async sendDataTask() {
      if (this.task.id) {
        await this.$store.dispatch('UPDATE_TASK', {
          id: this.task.id,
          date: this.task.date,
          time: this.task.time,
          description: this.task.description,
          state: this.task.state,
          desc_state: this.task.desc_state,
          type_id: this.task.type.id,
          user_id: this.task.manager.id,
          client_id: this.$route.params.id,
          options: {
            date: today,
          },
        });
        this.$notify({
          title: 'Успешно',
          message: 'Задача успешно обновлена',
          duration: 1500,
          type: 'success',
        });
        await this.handleClose();
        return true;
      }
      await this.$store.dispatch('STORE_TASK', { ...this.task, options: { date: today } });
      this.$notify({
        title: 'Успешно',
        message: 'Задача успешно добавлена',
        duration: 1500,
        type: 'success',
      });
      await this.handleClose();
    },
    async handleClose() {
      await this.fetch({ date: today });
      await this.$store.dispatch('RESET_TASK');
      this.visible = false;
    },
    selectClent(client) {
      this.client = client;
      this.$store.commit('SET_TASK', { ...this.task, client_id: client.id });
    },
    async handleDelete() {
      this.$confirm('Вы точно хотите удалить?', 'Предупреждение', {
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Закрыть',
        type: 'warning',
      })
        .then(async () => {
          this.loading = true;
          try {
            await this.$store.dispatch('DELETE_TASK', {
              id: this.task.id,
              options: { date: moment().format('YYYY-MM-DD') },
            });
            this.$notify({
              title: 'Успешно',
              message: 'Задача успешно удалена',
              duration: 1500,
              type: 'success',
            });
            await this.handleClose();
          } catch (error) {
            this.$notify.error({
              title: 'Ошибка',
              message: 'Ошибка',
              duration: 1500,
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>
