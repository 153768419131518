<template>
  <div class="board-column">
    <el-col :span="8">
      <div v-loading="loading" class="board__item">
        <h3
          :data-value="column.value"
          class="title is-5 is-flex is-align-items-center is-justify-content-space-between"
        >
          {{ column.label }}
          <el-button
            size="small"
            type="text"
            icon="el-icon-circle-plus-outline"
            @click="openModalAddTask"
          >
            Добавить задачу
          </el-button>
        </h3>
        <draggable
          :list="filteredTask(column.tasks)"
          :move="checkMove"
          group="tasks"
          class="board__cards"
          @change="reload"
        >
          <board-card
            v-for="(element, index) in filteredTask(column.tasks)"
            :key="element.id + '' + index"
            :task="element"
          />
        </draggable>
      </div>
    </el-col>
    <board-modal
      v-if="modal.show"
      :show.sync="modal.show"
      :title="modal.title"
      :date="modal.date"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import BoardCard from '../board-card/board-card.vue';
import BoardModal from '../board-modal/board-modal.vue';

export default {
  name: 'BoardColumn',
  components: {
    draggable,
    BoardCard,
    BoardModal,
  },
  props: {
    column: {
      required: true,
      type: Object,
    },
    reloadTasks: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      loading: false,
      modal: {
        show: false,
        title: 'Добавить задачу',
        date: null,
      },
    };
  },
  methods: {
    async reload() {
      this.loading = true;
      await this.reloadTasks();
      this.loading = false;
    },
    async checkMove(e) {
      const date = e.to.parentNode.querySelector('.title').dataset.value;
      const { id } = e.draggedContext.element;
      await this.$store.dispatch('UPDATE_TASK', { id, date });
    },
    openModalAddTask() {
      this.modal.show = true;
      this.modal.date = this.column.value;
    },
    filteredTask(tasks) {
      if (tasks) {
        return tasks.filter((task) => task.id);
      }
      return [];
    },
  },
};
</script>
