<template>
  <div class="board-card">
    <el-card shadow="hover" class="board__card mb-2">
      <div class="board__card__item">
        <el-tag class="mb-4" :type="task.state ? 'success' : 'danger'">
          {{ task.state ? 'Выполнена' : 'Не выполнена' }}
        </el-tag>
        <p class="is-size-6">
          {{ task.description }}
        </p>
        <br />
        <p>
          <el-button size="small" type="text" @click="openClient(task.client.id)">
            {{ task.client.name }}
          </el-button>
        </p>
        <p>
          <small>{{ task.client.messenger }}</small> -
          <small>{{ task.client.messenger_login }}</small>
        </p>
        <p>
          <small>{{ task.client.phone }}</small>
        </p>
        <p>
          <small
            >ул {{ task.client.address[0] ? task.client.address[0].street : '-' }} д.{{
              task.client.address[0] ? task.client.address[0].house : '-'
            }}</small
          >
        </p>
        <div class="is-flex mt-4 is-justify-content-space-between">
          <div class="date is-flex is-align-items-center">
            <i class="el-icon-time is-size-6 mr-2" />
            <span class="is-size-7">{{ task.time }}</span>
          </div>
          <div class="open-card is-flex is-flex-direction-column">
            <el-button
              type="primary"
              size="mini"
              class="mt-2"
              icon="el-icon-view"
              plain
              @click="openTask"
            >
              Просмотреть
            </el-button>
          </div>
        </div>
      </div>
    </el-card>
    <board-modal
      v-if="modal.show"
      :show.sync="modal.show"
      :task-id="modal.id"
      :title="modal.title"
    />
  </div>
</template>

<script>
import BoardModal from '../board-modal/board-modal.vue';

export default {
  name: 'BoardCard',
  components: { BoardModal },
  props: {
    task: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      modal: {
        show: false,
        id: 0,
        title: 'Просмотр задачи',
      },
    };
  },
  methods: {
    openTask() {
      this.modal.show = true;
      this.modal.id = this.task.id;
    },
    openClient(id) {
      this.$router.push(`/client/${id}/info`);
    },
  },
};
</script>
